import { graphql } from "gatsby";
import React from "react";

import AthleteDetail from "../components/AthleteDetail";
import GearList from "../components/GearList";
import LastUpdatedFooter from "../components/LastUpdatedFooter";
import BannerWithEyebrow from "../components/banners/BannerWithEyebrow";
import Layout from "../components/meta/Layout";
import Seo from "../components/meta/Seo";
import { mainGearType, sports } from "../utilities/sport-mapping";

export const athleteQuery = graphql`
  query AthleteQuery($slug: String) {
    contentfulAthlete(slug: { eq: $slug }) {
      name
      firstName

      updatedAt(formatString: "M/DD/YYYY")

      sport
      image {
        gatsbyImageData
      }

      height
      weight
      birthday(formatString: "M/DD/YYYY")
      country

      verified

      sponsors {
        name
        website
        affiliateUrl
        logo {
          gatsbyImageData
        }
      }

      imageCreditAccount
      imageCreditAccountName

      instagram
      youtube
      vimeo

      gear {
        name
        type
        brand {
          name
        }
        modelName
        modelYear
        affiliateUrl
        image {
          gatsbyImageData
        }
      }

      setups {
        displayName
        gear {
          name
          type
          brand {
            name
          }
          modelName
          modelYear
          affiliateUrl
          image {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export default function Athlete(props) {
  const { data } = props;
  const athleteData = data.contentfulAthlete;

  const description = `Check out ${
    athleteData.name
  }'s ${new Date().getFullYear()} ${
    sports[athleteData.sport]
  } gear list. See what ${
    mainGearType[athleteData.sport]
  }, boots, and bindings they're riding this winter season.`;

  return (
    <Layout>
      <Seo title={athleteData.name} description={description} />
      <AthleteDetail athleteData={athleteData} />
      {athleteData.gear && (
        <div>
          <BannerWithEyebrow
            eyebrow="Checkout"
            headline={`${athleteData.firstName}'s Gear list`}
          />
          <GearList gear={athleteData.gear} athlete={athleteData.name} />
          {athleteData.setups?.map((setup, index: number) => (
            <GearList
              key={index}
              headline={setup.displayName}
              gear={setup.gear}
              athlete={athleteData.name}
            />
          ))}
          <LastUpdatedFooter
            name={athleteData.name}
            lastUpdatedDate={athleteData.updatedAt}
          />
        </div>
      )}
    </Layout>
  );
}
