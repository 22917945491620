import * as React from "react";
import styled from "styled-components";

import { colors } from "../../styles/colors";
import { ContentWrapper, SmSection } from "../../styles/structures";
import { H1, H5 } from "../../styles/text-styles";

interface BannerWithEyebrowProps {
  eyebrow: string;
  headline: string;
}

export default function BannerWithEyebrow(props: BannerWithEyebrowProps) {
  const { eyebrow, headline } = props;

  return (
    <SmSection>
      <ContentWrapper>
        <Eyebrow>{eyebrow}</Eyebrow>
        <Headline>{headline}</Headline>
      </ContentWrapper>
    </SmSection>
  );
}

const Eyebrow = styled(H5)`
  text-align: center;
  color: ${colors.primary1};
  margin-bottom: 24px;
`;

const Headline = styled(H1)`
  text-align: center;
`;
