import React from "react";
import styled from "styled-components";

import { colors } from "../../styles/colors";

interface ShopButtonProps {
  shoppingUrl: string;
}

export default function ShopButton(props: ShopButtonProps) {
  return (
    <Button id="athlete_shop_click" href={props.shoppingUrl} target="_blank">
      Shop
    </Button>
  );
}

const Button = styled.a`
  background-color: ${colors.primary1};
  box-sizing: border-box;
  padding: 12px;
  border-radius: 16px;
  border-style: none;

  color: ${colors.white};
  font-size: 16px;
  font-weight: 600;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 32px;
  width: 64px;

  transition: 0.2s ease-out;

  :hover {
    filter: brightness(1.1);
  }
`;
