import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";
import styled from "styled-components";

import { breakpoints } from "../styles/breakpoints";
import { colors } from "../styles/colors";
import { H4, H6, P } from "../styles/text-styles";
import ShopButton from "./buttons/ShopButton";

interface GearItemProps {
  name: string;
  type: string;
  brand: string;
  modelName: string;
  modelYear: string;
  image: any;
  shoppingUrl: string;
  athlete: string;
}

export default function GearItem(props: GearItemProps) {
  const {
    name,
    type,
    brand,
    modelName,
    modelYear,
    image,
    shoppingUrl,
    athlete,
  } = props;

  return (
    <Panel>
      <a id="athlete_shop_click" href={shoppingUrl} target="_blank">
        <Image
          image={getImage(image)!}
          alt={athlete + " " + type + " - " + name}
        />
      </a>
      <Body>
        <Brand>{brand}</Brand>
        <ModelName>{modelName}</ModelName>
        <BottomWrapper>
          <ModelYear>{modelYear}</ModelYear>
          <ShopButton shoppingUrl={shoppingUrl} />
        </BottomWrapper>
      </Body>
    </Panel>
  );
}

const Panel = styled.div`
  border-radius: 12px;
  background-color: ${colors.gray2};
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
`;

const Image = styled(GatsbyImage)`
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  width: 100%;
`;

const Body = styled.div`
  padding: 24px;

  ${breakpoints.md} {
    padding: 36px;
  }
`;

const Brand = styled(H6)`
  color: ${colors.gray4};
`;

const ModelName = styled(H4)`
  margin-bottom: 16px;
`;

const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModelYear = styled(P)`
  color: ${colors.gray4};
`;
