import styled from "styled-components";

import { breakpoints } from "./breakpoints";

export const Section = styled.section`
  margin-top: 64px;
  margin-bottom: 64px;

  ${breakpoints.md} {
    margin-top: 128px;
    margin-bottom: 128px;
  }
`;

export const SmSection = styled.section`
  margin-top: 36px;
  margin-bottom: 36px;

  ${breakpoints.md} {
    margin-top: 64px;
    margin-bottom: 64px;
  }
`;

export const ContentWrapper = styled.div`
  padding: 0 24px;

  ${breakpoints.md} {
    padding: 0 36px;
  }

  ${breakpoints.lg} {
    padding: 0 106px;
  }
`;
