import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import { CircleFlag } from "react-circle-flags";
import { FaCheck, FaInstagram, FaVimeo, FaYoutube } from "react-icons/fa";
import styled from "styled-components";

import { breakpoints } from "../styles/breakpoints";
import { colors } from "../styles/colors";
import { Section } from "../styles/structures";
import { H3, H5, H6, P } from "../styles/text-styles";
import { countryCodes } from "../utilities/country-codes";
import { sports } from "../utilities/sport-mapping";

export default function AthleteDetail(props) {
  const { athleteData } = props;

  return (
    <Section>
      <Wrapper>
        <AthleteImageWrapper>
          <AthleteImage
            image={getImage(athleteData.image)!}
            alt={athleteData.name}
          ></AthleteImage>
          <ImageCreditWrapper>
            <ImageCredit>
              Image via
              <a href={athleteData.imageCreditAccount} target="_blank">
                <span> {athleteData.imageCreditAccountName} </span>
              </a>
              on
            </ImageCredit>
            <FaInstagram color="white" size={24} />
          </ImageCreditWrapper>
        </AthleteImageWrapper>

        <Panel>
          <div>
            <NameAndCheck>
              <H3>{athleteData.name}</H3>
              {athleteData.verified && (
                <CheckCircle
                  title={"Info verified by the athlete or their sponsors"}
                  size={20}
                />
              )}
            </NameAndCheck>
            <Sport>{sports[athleteData.sport]}</Sport>

            {athleteData.height && (
              <Height>Height: {athleteData.height}</Height>
            )}
            {athleteData.weight && (
              <Weight>Weight: {athleteData.weight}lbs</Weight>
            )}
          </div>

          <div>
            {athleteData.sponsors && (
              <SponsorsHeading>Sponsors:</SponsorsHeading>
            )}
            <SponsorsWrapper>
              {athleteData.sponsors?.map((sponsor, index: number) => (
                <a
                  id="athlete_sponsor_click"
                  href={sponsor.affiliateUrl || sponsor.website}
                  target="_blank"
                  key={index}
                >
                  <Sponsor image={getImage(sponsor.logo)} alt={sponsor.name} />
                </a>
              ))}
            </SponsorsWrapper>

            <Divider />
            <LowerWrapper>
              <div>
                {athleteData.birthday && (
                  <DateOfBirth>Birthday: {athleteData.birthday}</DateOfBirth>
                )}
                <CountryWrapper>
                  <P>{athleteData.country}</P>
                  <CircleFlag
                    countryCode={countryCodes[athleteData.country]}
                    height="18"
                  />
                </CountryWrapper>
              </div>
              <SocialWrapper>
                {athleteData.instagram && (
                  <a href={athleteData.instagram} target="_blank">
                    <FaInstagram color="white" size={24} />
                  </a>
                )}
                {athleteData.youtube && (
                  <a href={athleteData.youtube} target="_blank">
                    <FaYoutube color="white" size={24} />
                  </a>
                )}
                {athleteData.vimeo && (
                  <a href={athleteData.vimeo} target="_blank">
                    <FaVimeo color="white" size={24} />
                  </a>
                )}
              </SocialWrapper>
            </LowerWrapper>
          </div>
        </Panel>
      </Wrapper>
    </Section>
  );
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  ${breakpoints.lg} {
    grid-template-columns: 1fr 1fr;
  }
`;

const AthleteImageWrapper = styled.div`
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0) 25%,
      transparent
    );

    ${breakpoints.lg} {
      border-radius: 12px 0 0 12px;
    }
  }
`;

const AthleteImage = styled(GatsbyImage)`
  border-radius: 12px 12px 0 0;
  display: block;
  max-width: 100%;

  ${breakpoints.lg} {
    border-radius: 12px 0 0 12px;
  }
`;

const ImageCreditWrapper = styled.div`
  position: absolute;
  bottom: 16px;
  width: 100%;

  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
`;

const ImageCredit = styled(P)`
  span {
    color: ${colors.gray4};
  }
`;

const Panel = styled.div`
  background-color: ${colors.gray2};
  border-radius: 0 0 12px 12px;

  padding: 24px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${breakpoints.md} {
    padding: 36px;
  }

  ${breakpoints.lg} {
    border-radius: 0 12px 12px 0;
  }
`;

const NameAndCheck = styled.div`
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CheckCircle = styled(FaCheck)`
  background-color: ${colors.primary3};
  border-radius: 18px;
  padding: 7px;
`;

const Sport = styled(H6)`
  margin-bottom: 16px;
`;

const Height = styled(H5)`
  color: ${colors.gray4};
`;

const Weight = styled(H5)`
  color: ${colors.gray4};
  margin-bottom: 24px;
`;

const SponsorsHeading = styled(P)`
  color: ${colors.gray4};
  margin-bottom: 8px;
`;

const SponsorsWrapper = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
`;

const Sponsor = styled(GatsbyImage)`
  width: 64px;
  height: 64px;
  border-radius: 6px;

  ${breakpoints.md} {
    width: 96px;
    height: 96px;
  }
`;

const Divider = styled.hr`
  border-style: solid;
  border-width: 1px 0px 0px;
  border-color: hsla(0, 0%, 100%, 0.1) #000 #000;

  margin-top: 24px;
  margin-bottom: 24px;
`;

const LowerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const DateOfBirth = styled(P)`
  color: ${colors.gray4};
`;

const CountryWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const SocialWrapper = styled.div`
  display: flex;
  gap: 12px;
`;
