import * as React from "react";
import styled from "styled-components";

import { breakpoints } from "../styles/breakpoints";
import { SmSection } from "../styles/structures";
import { H3 } from "../styles/text-styles";
import GearItem from "./GearItem";

export default function GearList(props) {
  const { headline, gear, athlete } = props;

  return (
    <SmSection>
      <ListHeadline>{headline}</ListHeadline>
      <GearWrapper>
        {gear?.map((gearItem, index: number) => (
          <GearItem
            key={index}
            name={gearItem.name}
            type={gearItem.type}
            brand={gearItem.brand.name}
            modelName={gearItem.modelName}
            modelYear={gearItem.modelYear}
            image={gearItem.image}
            shoppingUrl={gearItem.affiliateUrl}
            athlete={athlete}
          />
        ))}
      </GearWrapper>
    </SmSection>
  );
}

const ListHeadline = styled(H3)`
  margin-bottom: 16px;
  text-align: center;

  ${breakpoints.md} {
    text-align: left;
  }
`;

const GearWrapper = styled.div`
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 24px;

  ${breakpoints.md} {
    grid-template-columns: 1fr 1fr;
  }

  ${breakpoints.lg} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
