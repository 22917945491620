import * as React from "react";
import styled from "styled-components";

import { H5 } from "..//styles/text-styles";
import { colors } from "../styles/colors";
import { ContentWrapper, Section } from "../styles/structures";

interface LastUpdatedFooterProps {
  name: string;
  lastUpdatedDate: string;
}

export default function LastUpdatedFooter(props: LastUpdatedFooterProps) {
  const { name, lastUpdatedDate } = props;

  return (
    <Section>
      <ContentWrapper>
        <Eyebrow>
          Gear info for {name} was last updated on {lastUpdatedDate}
        </Eyebrow>
      </ContentWrapper>
    </Section>
  );
}

const Eyebrow = styled(H5)`
  text-align: center;
  color: ${colors.gray4};
`;
