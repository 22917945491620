export const countryCodes: Record<string, string> = {
  Australia: "au",
  Austria: "at",
  Belgium: "be",
  Canada: "ca",
  China: "cn",
  Czechia: "cz",
  Finland: "fi",
  France: "fr",
  Germany: "de",
  Iceland: "is",
  Italy: "it",
  Japan: "jp",
  Mexico: "mx",
  "New Zealand": "nz",
  Norway: "no",
  Russia: "ru",
  Spain: "es",
  Sweden: "se",
  Switzerland: "ch",
  "United States": "us",
};
